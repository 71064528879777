import usePQTranslations from "@/commons/hooks/usePQTranslations";
import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";
import LocationUtility from "@/commons/utilities/LocationUtility";
import { LoadingButton } from "@mui/lab";
import {
  useGetLeafletsLazyQuery,
  OfferContainersSorting,
  Grouping,
} from "generated/graphql/types";
import { useRef } from "react";
import { leafletsVar } from "./reactivity-vars";

const LoadMoreBtn = () => {
  const t = usePQTranslations();

  const urlLocation = LocationUtility.getUrlLocation();
  const userLocation = LocationUtility.getUserLocation();


  let nearLocation = null;
  if (urlLocation?.latitude && urlLocation?.longitude) {
    nearLocation = `${urlLocation.latitude},${urlLocation.longitude}`;
  } else if (userLocation?.latitude && userLocation?.longitude) {
    nearLocation = `${userLocation.latitude},${userLocation.longitude}`;
  }

  const [getLeaflets, { data, loading }] = useGetLeafletsLazyQuery({
    onCompleted: () =>
      leafletsVar([...leafletsVar(), ...data?.OfferContainers?.data]),
  });

  const page = useRef(1);

  const setPage = async () => {  
    page.current = page.current + 1;
    getLeaflets({
      variables: {
        page: page.current,
        limit: 12,
        near: nearLocation,
        sorting: OfferContainersSorting.Top,
        grouping: Grouping.RetailerAndName,
      },
    });
  };

  return (
    <LoadingButton
      onClick={setPage}
      variant="contained"
      color="secondary"
      loading={loading}
      startIcon={<PQIcon icon={"add"} />}
      fullWidth
    >
      {t("leaflets.load_more")}
    </LoadingButton>
  );
};

export { LoadMoreBtn };
