import React, { useRef, useState, useEffect } from "react";

import useOnScreen from "@/commons/hooks/useOnScreen";

// Lazy render when scroll into view
export const LazyRender = ({ children, ssr = false, placeholderHeight = 400 }) => {
  const componentRef = useRef();
  const isOnScreen = useOnScreen(componentRef);

  const [isOnScreenTriggerRef, setIsOnScreenTriggerRef] = useState(ssr ? true : false);

  useEffect(() => {
    if (!ssr) {
      if (!isOnScreenTriggerRef) {
        setIsOnScreenTriggerRef(isOnScreen);
      }
    }
  }, [isOnScreen, isOnScreenTriggerRef, ssr]);
  
  const isServer = typeof window === 'undefined';
  const isToRender = (isOnScreenTriggerRef || (ssr && isServer))

  const style = isToRender ? {} : { height: placeholderHeight }

  return (
    <div
      data-lazy_render={isOnScreenTriggerRef}
      ref={componentRef}
      style={style}
    >
      {isToRender && children}
    </div>
  );
};

export default LazyRender;
