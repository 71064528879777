import { useState, useEffect, RefObject } from "react";

// This custom hook gets a ref and observes it
const useOnScreen = (ref: RefObject<Element>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);

  return isIntersecting;
};
export default useOnScreen;
