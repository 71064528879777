import {
  City,
  GetLeafletsDocument,
  GetLeafletsQuery,
  Grouping,
  Offer,
  OfferContainer,
  OfferContainerCommonParamsFragment,
  OfferContainersSorting,
  Retailer,
} from "generated/graphql/types";
import {
  usePQGlobalThis,
  useSelectorPQGlobalThis,
} from "@/commons/hooks/usePQGlobalThis";

import { AdsSlotsContants } from "@/commons/ui/constants/AdsConstants";
import Card from "@/commons/ui/components/card/Card";
import CarouselUtility from "@/commons/utilities/CarouselUtility";
import CityUtils from "@/commons/dataset/utils/CityUtils";
import { FeaturedLeaflets } from "./FeaturedLeaflets";
import { Hidden } from "@mui/material";
import Layout from "@/commons/ui/components/layout/Layout";
import LocationUtility from "@/commons/utilities/LocationUtility";
import Navigator from "@/commons/utilities/Navigator";
import PQHead from "@/commons/ui/components/pq-head/PQHead";
import { RoutesKeys } from "@/commons/keys";
import TwoColumns from "@/commons/ui/components/two-columns/TwoColumns";
import TwoColumnsMain from "@/commons/ui/components/two-columns/TwoColumnsMain";
import TwoColumnsSide from "@/commons/ui/components/two-columns/TwoColumnsSide";
import { ccontextSelector } from "@/commons/redux/selectors/ccontext";
import {
  checkEnabledADV,
  checkEnabledADVWithoutBot,
} from "@/commons/ui/components/advertisement/Advertisement";
import { client } from "apollo";
import dynamic from "next/dynamic";
import { leafletsVar } from "./reactivity-vars";
import { useCallback } from "react";
import usePQRoutes from "@/commons/hooks/usePQRoutes";
import usePQTranslations from "@/commons/hooks/usePQTranslations";
import { useSelector } from "react-redux";
import { IBreadCrumbSection } from "@/commons/ui/components/breadcrumb/BreadCrumb";

import LazyRender from "@/commons/ui/components/lazy-render/LazyRender";
import BreadcrumbListJsonLD from "@/jsonLD/BreadcrumbListJsonLD";

const MainAdv = dynamic(() => import("@/views/home-page/ads/MainAdv"), {
  ssr: true,
});

const ADVSerpFooter = dynamic(() => import("./ads/SerpFooterAdv"), {
  ssr: true,
});

const CategoriesList = dynamic(
  () => import("@/commons/ui/components/categories-list/CategoriesList"),
  {
    ssr: true,
  },
);

// const MyPromoQui = dynamic(
//   () => import("@/commons/ui/components/my-promoqui/MyPromoQui"),
//   {
//     ssr: true,
//   },
// );

const LastLeaflets = dynamic(() => import("./LastLeaflets"), {
  ssr: true,
});

const TopRetailersCarousel = dynamic(
  () =>
    import(
      "@/commons/ui/components/top-retailers-carousel/TopRetailersCarousel"
    ),
  {
    ssr: true,
  },
);

const TopOffers = dynamic(() => import("./TopOffers"), {
  ssr: true,
});

const HomePageCity = dynamic(() => import("./HomePageCity"), {
  ssr: true,
});

const MapStoresNear = dynamic(() => import("./MapStoresNear"), { ssr: false });

const CitiesTop = dynamic(() => import("./CitiesTop"), {
  ssr: true,
});

const NoPreciseLocation = dynamic(
  () => import("@/commons/ui/components/no-precise-location/NoPreciseLocation"),
  {
    ssr: true,
  },
);

interface Props {
  seoInfo: any;
  seoTitle: string;
  seoDescription: string;
  leaflets: OfferContainerCommonParamsFragment[];
  leafletsForInterval: OfferContainerCommonParamsFragment[];
  topRetailers: Retailer[];
  topCities: City[];
  featuredLeaflets: OfferContainer[];
  retailerFavourites: Retailer[];
  offersRetailer: Offer[];
  currentBreadcrumb: IBreadCrumbSection[];
}

// Usata quella lato server; quando cambia la location la pagina viene comunque ricaricata
const getLeaflets = async ({ userLocation }) => {
  const { data } = await client.query<GetLeafletsQuery>({
    query: GetLeafletsDocument,
    variables: {
      page: 1,
      limit: 12,
      near:
        userLocation?.latitude && userLocation?.longitude
          ? `${userLocation.latitude},${userLocation.longitude}`
          : null,
      sorting: OfferContainersSorting.Top,
      grouping: Grouping.RetailerAndName,
    },
  });

  leafletsVar(data.OfferContainers.data);
};

const HomePage = (props: Props) => {
  usePQGlobalThis(props);
  const routes = usePQRoutes();

  const t = usePQTranslations();

  const { pqConfs } = useSelectorPQGlobalThis();
  const enablePlaceholderForBot = pqConfs.ads.enablePlaceholderForBot;

  const { rStateHeaders, rStateIsBot, rStateIsChromeLighthouse } =
    useSelector(ccontextSelector);

  const renderADVMustHead = useCallback(() => {
    if (
      Navigator.isMobile(rStateHeaders) ||
      !checkEnabledADVWithoutBot(AdsSlotsContants.MAST_PG)
    )
      return null;

    if (!enablePlaceholderForBot && rStateIsBot) return null;

    return <MainAdv notRenderADV={rStateIsBot} />;
  }, [enablePlaceholderForBot, rStateHeaders, rStateIsBot]);

  const slidesScrollByDev = useCallback(() => {
    return CarouselUtility.slidesToScrollByDevice(rStateHeaders);
  }, [rStateHeaders]);

  leafletsVar(props.leaflets);

  // Disable image lazyload for all bots but not for ChromeLighthouse and Users
  const lazyLoad = !(rStateIsBot && !rStateIsChromeLighthouse);

  // Disable LazyRender for all bots but not for ChromeLighthouse and Users
  const doSSR = rStateIsBot && !rStateIsChromeLighthouse;

  const isLocalPage = LocationUtility.isLocalPage();
  const location = isLocalPage
    ? LocationUtility.getUrlLocation()
    : LocationUtility.getUserLocation();
  const userLocation = LocationUtility.getUserLocation();

  let locationName = null;
  if (isLocalPage) {
    locationName = location?.city?.name;
  } else {
    locationName =
      rStateIsBot || location.default
        ? null
        : location.city
        ? location?.city?.name
        : location.shortAddress;
  }

  const getUrlLatestLeaflet = () => {
    let url = routes(RoutesKeys.LEAFLET_PREVIEW);
    if (
      !location.default &&
      location.city &&
      !CityUtils.isSmallCity(location.city) &&
      isLocalPage &&
      location.slug
    ) {
      url = `/${location.slug}${url}`;
    }
    return url;
  };

  const { pqImages } = useSelectorPQGlobalThis();
  const placeholder = pqImages.imagePlaceholder;

  const renderPositionAlert = useCallback(() => {
    let positionALert;
    if (!location.isPrecise && !rStateIsBot) {
      const PositionAlert = dynamic(
        () => import("@/commons/ui/components/position-alert/PositionAlert"),
      );

      positionALert = (
        <Hidden mdDown>
          <Card title={t("location.improve_position")}>
            <PositionAlert lazyLoad={lazyLoad} />
          </Card>
        </Hidden>
      );
    }
    if (!positionALert) return;

    return positionALert;
  }, [lazyLoad, location.isPrecise, rStateIsBot, t]);

  return (
    <>
      <PQHead
        title={props.seoTitle}
        description={props.seoDescription}
        environment={process.env.NEXT_PUBLIC_COUNTRY}
      >
        <>
          <link rel="preload" as="image" href={placeholder} />
          {isLocalPage && (
            <script
              className="structured-data-list"
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: BreadcrumbListJsonLD(props.currentBreadcrumb),
              }}
            ></script>
          )}
        </>
      </PQHead>
      <Layout breadcrumb={props.currentBreadcrumb || []}>
        {renderADVMustHead()}
        <TwoColumns>
          <TwoColumnsMain>
            {renderPositionAlert()}
            <FeaturedLeaflets
              locationName={locationName}
              lazyLoad={lazyLoad}
              skipLazyLoadForFirstLeaflets={4}
            />
            {checkEnabledADV(AdsSlotsContants.SERP_RTL_1, rStateIsBot) && (
              <LazyRender ssr={doSSR}>
                <ADVSerpFooter slot={AdsSlotsContants.SERP_RTL_1} />
              </LazyRender>
            )}
          </TwoColumnsMain>

          <TwoColumnsSide>
            <Card
              title={t("filter.filter_by_category")}
              headingLevel={1}
              collapsable={false}
            >
              <LazyRender ssr={doSSR}>
                <CategoriesList
                  slidesToScroll={slidesScrollByDev()}
                  urlLatestLeaflet={getUrlLatestLeaflet()}
                />
              </LazyRender>
            </Card>
            {/* <Hidden mdDown>
              <LazyRender ssr={doSSR}>
                <MyPromoQui withCard={true} />
              </LazyRender>
            </Hidden> */}
          </TwoColumnsSide>
        </TwoColumns>

        <LazyRender ssr={doSSR}>
          <LastLeaflets
            slidesToScroll={slidesScrollByDev()}
            leaflets={props.leafletsForInterval}
            locationName={locationName}
            lazyLoad={lazyLoad}
          />
        </LazyRender>

        <Card
          headingLevel={2}
          title={
            locationName
              ? t("stores.highlighted_retailers_near", {
                  location: locationName,
                })
              : t("stores.highlighted_retailers")
          }
        >
          <LazyRender ssr={doSSR}>
            <TopRetailersCarousel
              slidesToScroll={slidesScrollByDev()}
              stores={props.topRetailers}
              lazyLoad={lazyLoad}
            />
          </LazyRender>
        </Card>

        {checkEnabledADV(AdsSlotsContants.SERP_FOOTER, rStateIsBot) && (
          <LazyRender ssr={doSSR}>
            <ADVSerpFooter slot={AdsSlotsContants.SERP_FOOTER} />
          </LazyRender>
        )}

        <LazyRender ssr={doSSR}>
          <TopOffers
            slidesToScroll={slidesScrollByDev()}
            offersRetailer={props.offersRetailer}
            locationName={locationName}
            lazyLoad={lazyLoad}
          />
        </LazyRender>

        <LazyRender ssr={doSSR}>
          <HomePageCity locationName={locationName} seoInfo={props.seoInfo} />
        </LazyRender>

        {!rStateIsBot && (
          <LazyRender ssr={false}>
            <MapStoresNear userLocation={userLocation} />
          </LazyRender>
        )}

        <LazyRender ssr={doSSR}>
          <CitiesTop cities={props.topCities} />
        </LazyRender>

        <LazyRender ssr={doSSR}>
          <NoPreciseLocation />
        </LazyRender>
      </Layout>
    </>
  );
};

export default HomePage;
