import LeafletsListItem from "@/commons/ui/components/leaflets-list-item/LeafletsListItem";
import Card from "@/commons/ui/components/card/Card";
import { Box, Grid } from "@mui/material";
import { OfferContainerCommonParamsFragment } from "generated/graphql/types";
import { useReactiveVar } from "@apollo/client";
import { leafletsVar } from "./reactivity-vars";
import { LoadMoreBtn } from "./LoadMoreBtn";
import usePQTranslations from "@/commons/hooks/usePQTranslations";

interface Props {
  locationName: string;
  skipLazyLoadForFirstLeaflets?: number;
  lazyLoad?: boolean;
}

const FeaturedLeafletsItems = ({
  skipLazyLoadForFirstLeaflets,
  lazyLoad = true,
}) => {
  const leaflets =
    useReactiveVar<OfferContainerCommonParamsFragment[]>(leafletsVar);

  const isLazyEnabledForPosition = (
    howManyPositionsToDisable: number,
    currPositionIndex: number,
  ) => {
    if (currPositionIndex < howManyPositionsToDisable) {
      return false;
    }
    return true;
  };

  return (
    <>
      {leaflets?.map((leaflet, index) => {
        return (
          <Grid item key={leaflet.id} xs={6} md={3}>
            <LeafletsListItem
              direction="horizontal"
              lazyLoad={
                lazyLoad &&
                isLazyEnabledForPosition(skipLazyLoadForFirstLeaflets, index)
              }
              leaflet={leaflet}
              position={index}
            />
          </Grid>
        );
      })}
    </>
  );
};

const FeaturedLeaflets = ({
  locationName,
  skipLazyLoadForFirstLeaflets,
  lazyLoad = true,
}: Props) => {
  const t = usePQTranslations();

  return (
    <Card
      title={
        locationName
          ? t("leaflets.highlighted_leaflets_near", { location: locationName })
          : t("leaflets.highlighted_leaflets")
      }
      headingLevel={2}
      collapsable={false}
    >
      <Grid
        marginTop={"1px"}
        paddingBottom={4}
        paddingTop={1}
        paddingX={{
          xs: 2,
          sm: 3,
        }}
        container
        columns={{
          xs: 12,
          sm: 12,
          md: 12,
        }}
        spacing={2}
      >
        <FeaturedLeafletsItems
          skipLazyLoadForFirstLeaflets={skipLazyLoadForFirstLeaflets}
          lazyLoad={lazyLoad}
        />
      </Grid>
      <Box paddingX={2} paddingY={2}>
        <LoadMoreBtn />
      </Box>
    </Card>
  );
};

export { FeaturedLeaflets };
